import React from "react";
import Layout from "components/Layout";
import StakingPanel from "components/StakingPanel";
import WagmiFist from "components/WagmiFist";


export default function HomePage() {

  return (
    <Layout title="Stake it till you make it - Longer and Bigger Pays Better!">
      {/* Hero header */}
      <div
        className="h-screen max-h-[500px] max-w-screen-2xl mx-auto flex flex-col md:flex-row
        justify-center items-center -my-0 lg:-my-14 px-4 lg:px-20"
      >
        <div className="w-full max-w-4xl order-1 md:order-none text-center md:text-left">
          <h1>Stake it till you make it - Longer and Bigger Pays Better!</h1>
          <p className="my-4 py-2">We're All Gonna Make It <WagmiFist /></p>
        </div>
      </div>
      <div className="bg-gray-50">
        <div
          className="lg:px-8 py-16 max-w-screen-2xl mx-auto"
        >
          <div className="mt-8 lg:mt-4">
            <StakingPanel />
          </div>
        </div>
      </div>
    </Layout>
  );
}
