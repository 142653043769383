import BigNumber from "bignumber.js";
import { DEFAULT_TOKEN_DECIMAL, WAGMI_DECIMAL } from "config";
import { Contract } from "@ethersproject/contracts";
import { callWithEstimateGas } from ".";

export const stakeFarm = async (
  contract: Contract,
  amount: string,
  pid: number
) => {
  const DECIMAL = pid === 1 ? DEFAULT_TOKEN_DECIMAL : WAGMI_DECIMAL;
  const value = new BigNumber(amount).times(DECIMAL).toFixed().toString();
  const tx = await callWithEstimateGas(contract, "deposit", [value]);
  const receipt = await tx.wait();
  return receipt.status;
};

export const unstakeFarm = async (
  contract: Contract,
  amount: string,
  pid: number
) => {
  const DECIMAL = pid === 1 ? DEFAULT_TOKEN_DECIMAL : WAGMI_DECIMAL;
  const value = new BigNumber(amount).times(DECIMAL).toFixed().toString();

  const tx = await callWithEstimateGas(contract, "withdraw", [value]);
  const receipt = await tx.wait();
  return receipt.status;
};

export const harvestFarm = async (contract: Contract) => {
  const tx = await callWithEstimateGas(contract, "deposit", ["0"]);
  const receipt = await tx.wait();
  return receipt.status;
};
