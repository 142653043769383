import { Token } from "config/entities/token";
import { serializeToken } from "state/user/hooks/helpers";
import { ChainId } from "./";
import { SerializedToken } from "./types";

const { MAINNET } = ChainId;

type TokenList = {
  [key in keyof typeof mainnetTokens]: Token;
};

interface SerializedTokenList {
  [symbol: string]: SerializedToken;
}

export const mainnetTokens = {
  wagmi: new Token(
    MAINNET,
    "0xF53FcB849d5e6Cc2D1cCABC0bb120AC192bfB5f8",
    9,
    "WAGMI",
    "wagmi4ever",
    "https://wagmi4ever.com"
  ),
  wbnb: new Token(
    MAINNET,
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    18,
    "WBNB",
    "Wrapped BNB",
    "https://www.binance.com/"
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(
    MAINNET,
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    18,
    "BNB",
    "BNB",
    "https://www.binance.com/"
  ),
  qsd: new Token(
    MAINNET,
    "0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2",
    18,
    "QSD",
    "QIAN second generation dollar",
    "https://chemix.io/home"
  ),
};

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID!;

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      const index = key as keyof TokenList;
      return { ...accum, [key]: mainnetTokens[index] } as TokenList;
    }, {} as TokenList);
  }

  return mainnetTokens;
};

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens();
  const serializedTokens = Object.keys(unserializedTokens).reduce(
    (accum, key) => {
      const index = key as keyof TokenList;
      return { ...accum, [key]: serializeToken(unserializedTokens[index]) };
    },
    {}
  );

  return serializedTokens;
};

export default tokens();
