import React from "react";
import { parseUnits } from "ethers/lib/utils";
import { formatBigNumber } from "utils/formatBalance";
import Button from "components/Button/Button";

interface ModalInputProps {
  max: string;
  symbol: string;
  onSelectMax?: () => void;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  addLiquidityUrl?: string;
  inputTitle?: string;
  decimals: number;
}

const ModalInput = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  addLiquidityUrl,
  inputTitle,
  decimals,
}: ModalInputProps) => {
  const isBalanceZero = max === "0" || !max;

  const displayBalance = (balance: string) => {
    if (isBalanceZero) {
      return "0";
    }

    const balanceUnits = parseUnits(balance, decimals);
    return formatBigNumber(balanceUnits, 4, decimals);
  };

  return (
    <div className="relative">
      <div
        className={`flex flex-col bg-white shadow-md p-2 w-full ${
          isBalanceZero ? "text-red-600" : ""
        }`}
      >
        <div className="flex justify-between mb-1">
          <p>{inputTitle}</p>
          <p>
            Balance: <span className="text-sm">{displayBalance(max)}</span>
          </p>
        </div>
        <div className="flex items-center justify-between">
          <input
            pattern={`^[0-9]*[.,]?[0-9]{0,${decimals}}$`}
            inputMode="decimal"
            step="any"
            min="0"
            onChange={onChange}
            placeholder="0"
            value={value}
            className="py-4 px-2 w-52 bg-gray-50 text-gray-900 outline-none ring
                ring-transparent focus:ring-blue-300"
          />
          <Button onClick={onSelectMax} className="!p-2 text-sm rounded-full">
            Max
          </Button>
        </div>
      </div>
      {isBalanceZero && (
        <p className="text-xs text-red-600">
          No tokens to stake{" "}
          <a
            className="text-xs font-medium text-blue-400 underline"
            href={addLiquidityUrl}
            rel="noopener nofollow"
          >
            Click to get {symbol}
          </a>
        </p>
      )}
    </div>
  );
};

export default ModalInput;
