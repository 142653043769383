import BigNumber from "bignumber.js";
import wagmiPoolAbi from "config/abi/wagmiReward.json";
import erc20ABI from "config/abi/erc20.json";
import multicall from "utils/multicall";
import { getAddress, getWagmiAddress } from "utils/addressHelpers";
import { SerializedFarmConfig } from "config/constants/types";

export const fetchFarmUserAllowances = async (
  account: string,
  farmToFetch: SerializedFarmConfig
) => {
  const wagmiAddress = getWagmiAddress(farmToFetch.pid);
  const lpContractAddress = getAddress(farmToFetch.lpAddresses);

  const call = {
    address: lpContractAddress,
    name: "allowance",
    params: [account, wagmiAddress],
  };

  try {
    const rawLpAllowances = await multicall(erc20ABI, [call]);
    const parsedLpAllowances = rawLpAllowances.map((lpBalance: BigNumber.Value) => {
      return new BigNumber(lpBalance).toJSON();
    });
    return parsedLpAllowances;
  } catch (error) {
    // allow trade test
    return ["0"];
  }
};

export const fetchFarmUserTokenBalances = async (
  account: string,
  farmsToFetch: SerializedFarmConfig[]
) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses);
    return {
      address: lpContractAddress,
      name: "balanceOf",
      params: [account],
    };
  });

  // mine

  const rawTokenBalances = await multicall(erc20ABI, calls);
  const parsedTokenBalances = rawTokenBalances.map(
    (tokenBalance: BigNumber.Value) => {
      return new BigNumber(tokenBalance).toJSON();
    }
  );
  return parsedTokenBalances;
};

export const fetchFarmUserStakedBalances = async (
  account: string,
  farmsToFetch: SerializedFarmConfig[]
) => {
  const calls = farmsToFetch.map((farm) => {
    const wagmiAddress = getWagmiAddress(farm.pid);

    return {
      address: wagmiAddress,
      name: "userInfo",
      params: [account],
    };
  });

  const userInfo = await multicall(wagmiPoolAbi, calls);
  const parsedStakedBalances = userInfo.map(
    ({ amount }: { amount: { _hex: BigNumber.Value } }) => {
      return new BigNumber(amount._hex).toJSON();
    }
  );
  return parsedStakedBalances;
};

export const fetchFarmUserEarnings = async (
  account: string,
  farmToFetch: SerializedFarmConfig
) => {
  const wagmiAddress = getWagmiAddress(farmToFetch.pid);

  const call = {
    address: wagmiAddress,
    name: "pendingReward",
    params: [account],
  };

  try {
    const rawEarnings = await multicall(wagmiPoolAbi, [call]);
    const parsedEarnings = rawEarnings.map((earnings: BigNumber.Value) => {
      return new BigNumber(earnings).toJSON();
    });
    return parsedEarnings;
  } catch (error) {
    return ["0"];
  }
};
