import BigNumber from "bignumber.js";
/**
 * Get farm APR value in %
 * @param totalTokenStaked Total token staked read from totalStaked the contract method
 * @param rewardPerBlock rewards per block from the contract method
 * @param lpPriceUsd Current Market Price of 1 unit of Staked Token at any given time
 * @param wagmiPriceBusd Current Market Price of 1 unit of WAGMI at any given time
 */
 export const getFarmAprV3 = (
  totalTokenStaked: BigNumber,
  rewardPerBlock: BigNumber,
  lpPriceUsd: BigNumber,
  wagmiPriceBusd: BigNumber
): { cakeRewardsApr: number; } => {
  let cakeRewardsAprAsNumber = 0;
  
  /*
  APR(%) = VAR/TSV × 100

  VARIABLES INVOLVED:-
  TSV = Total Staked Value (in $USD)
  VAR = Value of Annual Rewards (in $USD)
  */

  /*
  calc for TSV
  ===========
  TSV = TS x pST
  TS = (Total Staked) is read from totalStaked
  pST = Current Market Price of 1 unit of Staked Token at any given time
  */
  const TS = totalTokenStaked;
  const pST = lpPriceUsd;
  const TSV = TS.times(pST);
  /* 
  calc for VAR
  ============
  VAR = AR x pRT
  AR = Annual Rewards (in qty of RewardToken) calculated by rewardPerBlock x 10,512,000
  pRT = The price of 1 unit of WAGMI.
  */

  const AR = rewardPerBlock.times(10512000);
  const pRT = wagmiPriceBusd;
  const VAR = AR.times(pRT);

  // final
  const APR = VAR.div(TSV).times(100);
  let cakeRewardsApr = APR;


  if (cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber();
  }
  return {cakeRewardsApr: cakeRewardsAprAsNumber};
};
