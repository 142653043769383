import { useCallback } from "react";
import { harvestFarm } from "utils/calls";
import { useWagmiContract, useWagmiOnlyContract } from "hooks/useContract";

const useHarvestFarm = (pid: number) => {
  const wagmiContract = useWagmiContract();
  const wagmiOnlyContract = useWagmiOnlyContract();
  const contract = pid === 1 ? wagmiContract : wagmiOnlyContract;

  const handleHarvest = useCallback(async () => {
    await harvestFarm(contract);
  }, [contract]);

  return { onReward: handleHarvest };
};

export default useHarvestFarm;
