export const siteUrl = "https://aspstake.io/";

export const links = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "Stake $WAGMI",
      to: "/stake-wagmi",
    },
    {
      name: "Buy $WAGMI",
      to: "https://pancakeswap.finance/swap?outputCurrency=0xf53fcb849d5e6cc2d1ccabc0bb120ac192bfb5f8",
      target: "_blank",
      rel: "noreferrer nofollow noopener",
    },
    {
      name: "Explore NFTs",
      to: "/our-nfts",
    },
    {
      name: "Whitepaper",
      to: "https://wagmi4ever.com/whitepaper.pdf",
    },
    {
      name: "View Contract",
      to: "https://bscscan.com/token/0xf53fcb849d5e6cc2d1ccabc0bb120ac192bfb5f8",
      target: "_blank",
      rel: "noreferrer nofollow noopener",
    }
  ];