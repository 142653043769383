import { useCallback } from "react";
import { unstakeFarm } from "utils/calls";
import { useWagmiContract, useWagmiOnlyContract } from "hooks/useContract";

const useUnstakeFarms = (pid: number) => {
  const wagmiContract = useWagmiContract();
  const wagmiOnlyContract = useWagmiOnlyContract();
  const contract = pid === 1 ? wagmiContract : wagmiOnlyContract;

  const handleUnstake = useCallback(
    async (amount: string) => {
      await unstakeFarm(contract, amount, pid);
    },
    [contract, pid]
  );

  return { onUnstake: handleUnstake };
};

export default useUnstakeFarms;
