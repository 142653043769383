import React from "react";
import WagmiFist from "./WagmiFist";

interface SiteLogoProps {
  description?: boolean;
}

export default function SiteLogo({ description }: SiteLogoProps) {
  return (
    <div className={`${description ? "text-right" : ""}`}>
      <span className="text-lg font-black font-sans">
        WAGMI4EVER
      </span>
      {description && (
        <p className="text-xs -mt-1 text-gray-500">
          We're All Gonna Make It <WagmiFist /> ($WAGMI)
        </p>
      )}
    </div>
  );
}
