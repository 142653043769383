import React, { useEffect } from "react";
import Navbar from "./Navbar";
import PrimaryFooter from "./PrimaryFooter";
import { Helmet } from "react-helmet";
import { usePollFarmsWithUserData } from "state/farms/hooks";
import { useLocation } from "react-router-dom";

export default function Layout(props: {
  children: React.ReactNode;
  title: string;
}) {
  const url = useLocation().pathname;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [url]);
  usePollFarmsWithUserData();
  return (
    <div>
      <Helmet title={props.title} titleTemplate={`%s ・ WAGMI4EVER`} />
      <Navbar />
      <div>
        {props.children}
        <PrimaryFooter />
      </div>
    </div>
  );
}
