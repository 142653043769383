import React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  active?: boolean;
  variant?: "primary" | "secondary" | "outlined";
}

export default function Button({
  className,
  label,
  active,
  variant = "primary",
  children,
  ...props
}: ButtonProps) {
  let variantClass = "";

  switch (variant) {
    case "primary":
      variantClass = "text-gray-50 bg-amber-600 hover:bg-amber-700 shadow-amber-600 hover:shadow-lg";
      break;

    case "secondary":
      variantClass =
        "bg-blue-700 text-white hover:bg-blue-800 focus:bg-blue-800 ring-blue-400";
      break;

    case "outlined":
      variantClass = "bg-transparent ring-1 text-amber-700";
      break;

    default:
      throw new Error("invalid variant type supplied");
  }

  return (
    <button
      className={`p-4 m-2 font-medium rounded-md outline-none transition-all duration-300 ring-amber-700 focus-within:ring-2 disabled:cursor-not-allowed disabled:opacity-40 ${variantClass} ${className}`}
      {...props}
      title={label}
    >
      {children || label}
    </button>
  );
}
