import BigNumber from "bignumber.js";
import erc20 from "config/abi/erc20.json";
import wagmiPool from "config/abi/wagmiReward.json";
import { getAddress, getWagmiAddress } from "utils/addressHelpers";
import { BIG_TEN } from "utils/bigNumber";
import getBnbPrice from "utils/getBnbPrice";
import multicall from "utils/multicall";
import { SerializedFarm, SerializedBigNumber } from "../types";

type PublicFarmData = {
  tokenAmountTotal: SerializedBigNumber;
  lpTotalInQuoteToken: SerializedBigNumber;
  lpTotalSupply: SerializedBigNumber;
  tokenPriceVsQuote: SerializedBigNumber;
  decimals: number;
  extras: {
    lpTokenBalanceMC: SerializedBigNumber;
    totalTokenStaked: SerializedBigNumber;
    rewardPerBlock: SerializedBigNumber;
    lpPrice: SerializedBigNumber;
  };
};

const fetchFarm = async (farm: SerializedFarm): Promise<PublicFarmData> => {
  const { lpAddresses, token, quoteToken, pid } = farm;
  const lpAddress = getAddress(lpAddresses);
  const wagmiAddress = getWagmiAddress(pid);

  const Erc20calls = [
    // Balance of token in the LP contract
    {
      address: token.address,
      name: "balanceOf",
      params: [lpAddress],
    },
    // Balance of quote token on LP contract
    {
      address: quoteToken.address,
      name: "balanceOf",
      params: [lpAddress],
    },
    // Balance of LP tokens in the wagmi smart contract (MC)
    {
      address: lpAddress,
      name: "balanceOf",
      params: [wagmiAddress],
    },
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: "totalSupply",
    },
    // Token decimals
    {
      address: token.address,
      name: "decimals",
    },
    // Quote token decimals
    {
      address: quoteToken.address,
      name: "decimals",
    },
  ];

  const [
    tokenBalanceLP,
    quoteTokenBalanceLP,
    lpTokenBalanceMC,
    lpTotalSupply,
    tokenDecimals,
    quoteTokenDecimals,
  ] = await multicall(erc20, Erc20calls);

  const wagmiCalls = [
    // Total token staked
    {
      address: wagmiAddress,
      name: "totalStaked",
      params: [],
    },
    // Get reward per block
    {
      address: wagmiAddress,
      name: "rewardPerBlock",
      params: [],
    },
  ];

  const [totalTokenStaked, rewardPerBlock] = await multicall(
    wagmiPool,
    wagmiCalls
  );

  const bnbPrice = new BigNumber(await getBnbPrice());

  // Ratio in % of LP tokens that are staked in the MC (master contract), vs the total number in circulation
  const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(
    new BigNumber(lpTotalSupply)
  );

  // Raw amount of token in the LP, including those not staked
  const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(
    BIG_TEN.pow(tokenDecimals)
  );
  const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(
    BIG_TEN.pow(quoteTokenDecimals)
  );
  // total supply
  const lpTotal = new BigNumber(lpTotalSupply).div(
    BIG_TEN.pow(quoteTokenDecimals)
  );
  // lpValue
  const lpPrice = quoteTokenAmountTotal
    .times(new BigNumber(2))
    .times(bnbPrice)
    .div(lpTotal);

  // Amount of quoteToken in the LP that are staked in the MC
  const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio);

  // Total staked in LP, in quote token value
  const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2));

  return {
    tokenAmountTotal: tokenAmountTotal.toJSON(),
    lpTotalSupply: lpTotal.toJSON(),
    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    decimals: pid === 1 ? 18 : 9,
    extras: {
      lpTokenBalanceMC: new BigNumber(lpTokenBalanceMC)
        .div(BIG_TEN.pow(tokenDecimals))
        .toJSON(),
      totalTokenStaked: new BigNumber(totalTokenStaked)
        .div(BIG_TEN.pow(tokenDecimals))
        .toJSON(),
      rewardPerBlock: new BigNumber(rewardPerBlock)
        .div(BIG_TEN.pow(tokenDecimals))
        .toJSON(),
      lpPrice: lpPrice.toJSON(),
    },
  };
};

export default fetchFarm;
