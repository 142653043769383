import React from "react";
import Layout from "components/Layout";

export default function NFTs() {
  return (
    <Layout title="Our NFTs">
      <div
        className="relative h-screen"
          dangerouslySetInnerHTML={{
            __html: `<iframe src='https://opensea.io/wagmi4ever?embed=true'
              width='100%'
              height='100%'
              frameborder='0'
              allowfullscreen></iframe>`,
          }}
        />
    </Layout>
  );
}
