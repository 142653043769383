import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [ 
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 1,
    lpSymbol: 'WAGMI-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xB72e5D91B769277e1F437815574569AeE4AEB203',
    },
    token: serializedTokens.wagmi,
    quoteToken: serializedTokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'WAGMI',
    lpAddresses: {
      97: '',
      56: '0xF53FcB849d5e6Cc2D1cCABC0bb120AC192bfB5f8',
    },
    token: serializedTokens.wagmi,
    quoteToken: serializedTokens.wagmi,
  }
]

export default farms
