import { ethers } from "ethers";
import { getWagmiAddress, getMulticallAddress, getBnbPriceHelperAddress, getWagmiOnlyAddress } from "./addressHelpers";
// ABI
import bep20Abi from "config/abi/erc20.json";
import MultiCallAbi from "config/abi/Multicall.json";
import wagmi from "config/abi/wagmiReward.json";
import bnbPriceHelper from "config/abi/bnbPriceHelper.json";
import { simpleRpcProvider } from "./providers";

export const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider | undefined
) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getBep20Contract = (
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, address, signer);
};

// Who is master chef?
export const getWagmiPoolContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(wagmi, getWagmiAddress(1), signer);
};

export const getWagmiOnlyContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(wagmi, getWagmiOnlyAddress(), signer);
};
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}


export const getBnbPriceHelperContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bnbPriceHelper, getBnbPriceHelperAddress(), signer)
}