import useLocalStorage from "hooks/useLocalStorage";
import { IconBaseProps } from "react-icons";
import { usePriceCakeBusd } from "state/farms/hooks";
import Button from "./Button/Button";
import NewsLetterModal from "./NewsLetterModal";
import SiteLogo from "./SiteLogo";
import WagmiFist from "./WagmiFist";
import useModal from "./widgets/Modal/useModal";

export interface SocialLinkProps {
  link: string;
  icon: (props: IconBaseProps) => JSX.Element;
}

export default function PrimaryFooter() {
  const wagmiPriceUsd = usePriceCakeBusd();
  const bal = wagmiPriceUsd.toFixed(13);
  // subscribed to newletter
  const [subscribed] = useLocalStorage("added_to_list", false);

  const [onPresentNewsLetter] = useModal(<NewsLetterModal />);

  return (
    <footer className="border-t bg-white px-4 py-5">
      <div>
        <div className="flex justify-center items-center gap-7 my-6 leading-3">
          <div className="w-56">
            <SiteLogo description />
            {!subscribed && (
              <Button
                onClick={onPresentNewsLetter}
                className="block !p-1 text-xs ml-auto mb-0 mx-0"
                variant="outlined"
              >
                Newsletter
              </Button>
            )}
          </div>
          <div className="text-sm font-medium w-40">
            Current price
            <div className="text-amber-700">${bal}</div>
          </div>
        </div>
        <div className="text-xs text-center text-gray-500">
          copyright &copy; {new Date().getFullYear()}
          {" - "} WAGMI4EVER <WagmiFist />
        </div>
      </div>
    </footer>
  );
}
