import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { BIG_TEN } from "./bigNumber";
import { getBnbPriceHelperContract } from "./contractHelpers";

const getBnbPrice = async () => {
  const bnbPriceHelper = getBnbPriceHelperContract();
  const price: ethers.BigNumber = await bnbPriceHelper.GetBNBCurrentPrice();
  const { _hex } = price;
  return new BigNumber(_hex).div(BIG_TEN.pow(8)).toJSON();
};
export default getBnbPrice;
