import { useCallback } from "react";
import { ethers, Contract } from "ethers";
import { useWagmiContract, useWagmiOnlyContract } from "hooks/useContract";
import { useCallWithGasPrice } from "hooks/useCallWithGasPrice";

const useApproveFarm = (lpContract: Contract, pid: number) => {
  const wagmiContract = useWagmiContract();
  const wagmiOnlyContract = useWagmiOnlyContract();
  const contract = pid === 1 ? wagmiContract : wagmiOnlyContract;

  const { callWithGasPrice } = useCallWithGasPrice();
  const handleApprove = useCallback(async () => {
    // console.log(masterChefContract);
    // debugger;
    const tx = await callWithGasPrice(lpContract, "approve", [
      contract.address,
      ethers.constants.MaxUint256,
    ]);
    const receipt = await tx.wait();
    return receipt.status;
  }, [lpContract, contract, callWithGasPrice]);

  return { onApprove: handleApprove };
};

export default useApproveFarm;
