import React, { useMemo } from "react";
import BigNumber from "bignumber.js";
import { DeserializedFarm } from "state/types";
import getLiquidityUrlPathParts from "utils/getLiquidityUrlPathParts";
import { BASE_ADD_LIQUIDITY_URL } from "config";
import CardHeading from "./CardHeading";
import CardActionsContainer from "./CardActionsContainer";

export interface FarmWithStakedValue extends DeserializedFarm {
  apr?: number;
  lpRewardsApr?: number;
}

interface FarmCardProps {
  farm: FarmWithStakedValue;
  displayApr: string | null;
  removed: boolean;
  cakePrice: BigNumber;
  account?: string;
}

export default function FarmCard({
  farm,
  cakePrice,
  account,
  displayApr,
}: FarmCardProps) {

  const lpLabel =
    farm.lpSymbol && farm.lpSymbol.toUpperCase().replace("PANCAKE", "");

  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: farm.quoteToken.address,
    tokenAddress: farm.token.address,
  });
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`;

  const earnings = useMemo(
    () =>
      account ? farm.userData?.earnings || new BigNumber(0) : new BigNumber(0),

    [account, farm.userData?.earnings]
  );

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 px-4 inline-block">
      <div
        className="bg-white rounded-xl relative z-10 overflow-hidden border border-primary
            border-opacity-20 py-10 px-6 sm:p-12 mb-10"
      >
        <CardHeading
          lpLabel={lpLabel}
          token={farm.token}
          quoteToken={farm.quoteToken}
          apr={displayApr}
          earnings={earnings}
        />
        <CardActionsContainer
          farm={farm}
          lpLabel={lpLabel}
          account={account}
          cakePrice={cakePrice}
          addLiquidityUrl={addLiquidityUrl}
        />
        <div>
          <span className="absolute right-0 top-7 z-[-1]">
            <svg
              width="77"
              height="172"
              viewBox="0 0 77 172"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="86" cy="86" r="86" fill="url(#paint0_linear)" />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="86"
                  y1="0"
                  x2="86"
                  y2="172"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.09" />
                  <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
}
