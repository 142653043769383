import React from "react";
import { BiCheckDouble } from "react-icons/bi";
import { RiTimerLine } from "react-icons/ri";

const Lists = ({ items }: { items: { text: string; checked?: boolean }[] }) => {
  return (
    <ul className="p-2 text-sm text-gray-900 text-opacity-100">
      {items.map((n, i) => {
        const completed = n.checked ? (
          <div className="inline-block bg-amber-500/20 rounded-full p-1.5">
            <BiCheckDouble className="text-amber-500 h-4 w-4" />
          </div>
        ) : (
          <div className="inline-block bg-amber-500/20 rounded-full p-1.5">
            <RiTimerLine className="text-amber-500 h-4 w-4" />
          </div>
        );
        return (
          <li
            key={i}
            className="font-light text-base my-2 flex items-center"
          >
            {completed}
            <p className="ml-3">{n.text}</p>
          </li>
        );
      })}
    </ul>
  );
};

const roadMap = [
  {
    id: 1,
    label: "PHASE 1",
    content: (
      <Lists
        items={[
          {
            text: "Fair Launch on 31st December 2021 20:00 UTC",
            checked: true,
          },
          { text: "Build Our Website", checked: true },
          { text: "1000 Telegram members", checked: true },
          { text: "WAGMI Staking", checked: true },
          { text: "Grow The Community" },
          { text: "NFT Collection" },
          { text: "Grow Social News" },
          { text: "1000 holders" },
        ]}
      />
    ),
  },
  {
    id: 2,
    label: "PHASE 2",
    content: (
      <Lists
        items={[
          { text: "Burn 100,000,000,000 $WAGMI (10 week burn scheduled)" },
          { text: "Promote Our Project On Twitter" },
          { text: "Coingeko Listing" },
          { text: "Dextools Trending" },
          { text: "Influencers / Celebrity Promotion" },
          { text: "Marketing Campaign on Popular Sites" },
        ]}
      />
    ),
  },
  {
    id: 3,
    label: "PHASE 3",
    content: (
      <Lists
        items={[
          { text: "10000 holders" },
          { text: "3000 Telegram members" },
          { text: "5M$ Market Cap reached" },
        ]}
      />
    ),
  },
  {
    id: 4,
    label: "PHASE 4",
    content: (
      <Lists
        items={[
          { text: "Charity donations" },
          { text: "25M$ Market Cap reached" },
          { text: "25000 holders" },
          { text: "CEX Listings" },
        ]}
      />
    ),
  },
];

interface TimelineLeafProps {
  label: string;
  content: React.ReactNode;
}

const LeftLeaf = ({ label, content }: TimelineLeafProps) => {
  return (
    <div className="mb-8 flex justify-between flex-row-reverse items-center w-full">
      <div className="order-1 w-5/12 hidden sm:block"></div>
      <div className="z-20 rounded-l-full flex items-center shrink-0 order-1 bg-white ring-1 ring-gray-500 shadow-xl p-2 ml-10 sm:ml-0">
        <h1 className="mx-auto font-semibold text-xs mb-0">{label}</h1>
      </div>
      <div className="order-1 bg-gray-100 rounded-lg shadow-xl w-10/12 sm:w-5/12 pl-6 py-4">
        {content}
      </div>
    </div>
  );
};

const RightLeaf = ({ label, content }: TimelineLeafProps) => {
  return (
    <div className="mb-8 flex justify-between items-center w-full">
      <div className="order-1 w-5/12 hidden sm:block"></div>
      <div className="z-20 rounded-r-full flex items-center shrink-0 order-1 bg-white ring-1 ring-gray-500 shadow-xl p-2 mr-10 sm:mr-0">
        <h1 className="mx-auto font-semibold text-xs mb-0">{label}</h1>
      </div>
      <div className="order-1 bg-gray-100 rounded-lg shadow-xl w-10/12 sm:w-5/12 pl-6 py-4">
        {content}
      </div>
    </div>
  );
};

const isOdd = (n: number) => n % 2 !== 0;

export default function Roadmap() {
  return (
    <div className="mx-auto w-full">
      <div className="relative wrap overflow-hidden py-10 px-3 h-full">
        {roadMap.map((item) => {
          return isOdd(item.id) ? (
            <RightLeaf
              key={item.id}
              label={item.label}
              content={item.content}
            />
          ) : (
            <LeftLeaf key={item.id} label={item.label} content={item.content} />
          );
        })}
      </div>
    </div>
  );
}
