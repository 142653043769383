import { useCallback } from "react";
import { stakeFarm } from "utils/calls";
import { useWagmiContract, useWagmiOnlyContract } from "hooks/useContract";

const useStakeFarms = (pid: number) => {
  const wagmiContract = useWagmiContract();
  const wagmiOnlyContract = useWagmiOnlyContract();
  const contract = pid === 1 ? wagmiContract : wagmiOnlyContract;

  const handleStake = useCallback(
    async (amount: string) => {
      await stakeFarm(contract, amount, pid);
    },
    [contract, pid]
  );

  return { onStake: handleStake };
};

export default useStakeFarms;
