import React from "react";
import logo from "../logo.png";

type WagmiSizes = "large" | "medium" | "small";

export default function WagiFist({ size = "small" }: { size?: WagmiSizes }) {
  let boxSize = 20;
  if (size === "medium") boxSize = 30;
  if (size === "large") boxSize = 40;
  return (
    <img
      src={logo}
      alt="Wagmi Fist"
      width={boxSize}
      height={boxSize}
      className="inline-block"
    />
  );
}
