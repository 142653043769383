import React from "react";
import Layout from "components/Layout";
import Button from "components/Button/Button";
import Roadmap from "components/widgets/Timeline/RoadMap";
import Link from "components/Link";
import CopyToClipboard from "components/widgets/CopyToClipboard";
import WagmiFist from "components/WagmiFist";
import NewsLetterModal from "components/NewsLetterModal";
import useModal from "components/widgets/Modal/useModal";
import useLocalStorage from "hooks/useLocalStorage";

const mails = ["wagmi@wagmi4ever.com"];

export default function HomePage() {
  const [onPresentNewsLetter] = useModal(<NewsLetterModal />);
  // subscribed to newletter
  const [subscribed] = useLocalStorage("added_to_list", false);

  return (
    <Layout title="We're All Gonna Make It ✊ ($WAGMI)">
      {/* Hero header */}
      <div
        className="h-screen max-h-[1000px] max-w-screen-2xl mx-auto flex flex-col md:flex-row
        justify-center items-center -my-0 lg:-my-14 px-4 lg:px-20"
      >
        <div className="w-full max-w-4xl order-1 md:order-none text-center md:text-left">
          <h1>
            WAGMI4EVER <WagmiFist size="large" />
          </h1>
          <p className="my-4 py-2">
            A blockchain community giving value to humanity. Every member of the
            community is regarded as a citizen regardless of their differences
            in race, religion, nationality and political belief.
          </p>
          <div className="my-4 -mx-2">
            <Link
              to="https://pancakeswap.finance/swap?outputCurrency=0xf53fcb849d5e6cc2d1ccabc0bb120ac192bfb5f8"
              className="p-1 text-white font-medium rounded-md mx-2 md:ml-0 lg:w-auto transition-colors
                duration-300 hover:bg-white"
            >
              <Button>Buy $WAGMI</Button>
            </Link>
            <Link
              to="/stake-wagmi"
              className="p-1 text-white font-medium rounded-md mx-2 md:ml-0 lg:w-auto transition-colors
                duration-300 hover:bg-white "
            >
              <Button>Stake $WAGMI</Button>
            </Link>
            <Link
              to="/our-nfts"
              className="p-1 text-white font-medium rounded-md mx-2 md:ml-0 lg:w-auto transition-colors
                duration-300 hover:bg-white "
            >
              <Button>NFTs</Button>
            </Link>
          </div>
        </div>
        <div
          className="w-full h-96 max-h-96 max-w-3xl
            bg-[url('../public/images/wagmi-freedom-stroke.svg')] bg-no-repeat bg-center bg-contain"
        />
      </div>
      <div className="bg-gray-50">
        <div className="py-12 px-4 lg:px-8 max-w-screen-2xl mx-auto flex flex-col md:flex-row items-center justify-center">
          <div className="max-w-xs md:max-w-sm lg:mx-10 text-center mb-8 md:mb-0">
            <div className="h-20 w-20 mx-auto mb-3">
              <img
                src="/images/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.svg"
                alt="Binance logo"
              />
            </div>
            <p className="text-sm">
              Built on the Binance Smart Chain by a group of tech enthusiasts
              and philanthropist looking to make the world a better place.
            </p>
          </div>
          <div className="max-w-xs md:max-w-sm lg:mx-10 text-center">
            <div className="h-20 w-20 mx-auto mb-3">
              <img src="/images/lock-coins.png" alt="Lock" />
            </div>
            <p className="text-sm">
              WAGMI embodies the struggle diamond hands go through. If you
              cannot HODL, you can't be rich. The WAGMI token is an affirmative
              proclamation that <b>WE'RE ALL GONNA MAKE IT</b> <WagmiFist />
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="py-12 px-4 lg:px-8 max-w-screen-2xl mx-auto flex flex-col justify-center text-center md:text-left">
          <div className="flex flex-col md:flex-row justify-start items-center mb-16 md:mb-0">
            <div
              className="h-60 w-60 inline-block mr-10
              bg-[url('../public/images/business-man.png')] bg-no-repeat bg-center bg-contain"
            />
            <p className="inline-block max-w-2xl">
              WAGMI is a movement for good, much more than just a token. It’s
              about manifesting your wildest crypto gains into reality.
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-end items-center">
            <p className="inline-block max-w-2xl order-1 md:order-none">
              WAGMI is here to shake up the meme-coin space, combining viral
              trends with rock-solid project development.
            </p>
            <div
              className="h-60 w-60 inline-block ml-10
              bg-[url('../public/images/money-construction-site.png')] bg-no-repeat bg-center bg-contain"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-50">
        <div
          className="lg:px-8 h-[500px] max-w-screen-2xl mx-auto
          flex flex-col justify-center text-center"
        >
          <h2>TOKENOMICS</h2>
          <div className="flex justify-center items-center text-center my-8 lg:my-4">
            <div className="mx-4">
              <h3>Buy Tax</h3>
              <p className="my-3 text-sm md:text-base">
                10% (redistributed to all $WAGMI HODLers)
              </p>
            </div>
            <div className="mx-4">
              <h3>Sell Tax</h3>
              <p className="my-3 text-sm md:text-base">
                14% (7% development, 7% buybacks and marketing)
              </p>
            </div>
          </div>
          <div className="max-w-full mt-4">
            <h4 className="my-4 text-gray-600">Smart Contract Address</h4>
            <CopyToClipboard content="0xF53FcB849d5e6Cc2D1cCABC0bb120AC192bfB5f8" />
          </div>
        </div>
      </div>
      <div>
        <div className="pt-32 lg:px-8 max-w-screen-2xl mx-auto">
          <h2 className="text-center mb-4">ROADMAP</h2>
          <Roadmap />
        </div>
      </div>
      <div className="bg-gray-50">
        <div
          id="contact-us"
          className="px-4 lg:px-8 h-[500px] max-w-screen-2xl mx-auto
            flex flex-col justify-center text-center"
        >
          <h2>CONTACT US</h2>
          <div className="mt-8 md:mt-4 max-w-4xl mx-auto">
            <p className="mb-4">
              We are always open and we welcome any questions you have for our
              team. If you wish to get in touch, please join our telegram or
              send a mail to <b>wagmi@wagmi4ever.com</b>. Someone from our team
              will get back to you shortly.
            </p>
            <Link
              to="mailto:wagmi@wagmi4ever.com"
              className="p-1 text-white font-medium rounded-md mx-4 lg:w-auto transition-colors
                duration-300 hover:bg-white"
            >
              <Button>Reach Out</Button>
            </Link>
            {!subscribed && (
              <Button onClick={onPresentNewsLetter}>Newsletter</Button>
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          className="px-4 lg:px-8 h-[500px] max-w-screen-2xl mx-auto
            flex flex-col justify-center text-center"
        >
          <h2>JOIN THE MOVEMENT</h2>
          <div className="mt-8">
            <p>
              WAGMI means community, and community means family. We're All Gonna
              Make It <WagmiFist /> ($WAGMI)
            </p>
            <div className="flex flex-col justify-center items-center mt-4 lg:flex-row lg:justify-center">
              {mails.map((mail, i) => (
                <Link
                  key={i}
                  to={`mailto:${mail}`}
                  className="p-1 text-white font-medium rounded-md mx-4 lg:w-auto transition-colors
                    duration-300 hover:bg-white "
                >
                  <Button>{mail}</Button>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
