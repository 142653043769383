import React from "react";
import { Link as ReactRouterLink, LinkProps } from "react-router-dom";

export default function Link({ children, to, ...other }: LinkProps) {
  const internal = /^\/(?!\/)/.test(to.toString());

  // Use React router Link for internal links, and <a> for others
  if (internal) {
    return (
      <ReactRouterLink to={to} {...other}>
        {children}
      </ReactRouterLink>
    );
  }
  return (
    <a href={to.toString()} rel="nofollow noreferrer noopener" {...other}>
      {children}
    </a>
  );
}
