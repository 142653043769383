import React, { useCallback, useMemo, useRef } from "react";
import { useFarms, usePriceCakeBusd } from "state/farms/hooks";
import BigNumber from "bignumber.js";
import { DeserializedFarm } from "state/types";
import { getFarmAprV3 } from "utils/apr";
import { useWeb3React } from "@web3-react/core";
import FarmCard, { FarmWithStakedValue } from "./FarmCard/FarmCard";

export const getDisplayApr = (cakeRewardsApr?: number) => {
  if (cakeRewardsApr !== undefined) {
    return `${cakeRewardsApr.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    })}%`;
  }
  return null;
};

export default function StakingPanel() {
  const chosenFarmsLength = useRef(0);

  const { account } = useWeb3React();
  const { data: farmsLP } = useFarms();
  const cakePrice = usePriceCakeBusd();

  // usePollReferralUserData();

  const activeFarms = farmsLP.filter(
    (farm) => farm.pid !== 0 && farm.multiplier !== "0X"
  );

  // console.log(totalTokenStaked, rewardPerBlock)

  const farmsList = useCallback(
    (farmsToDisplay: DeserializedFarm[]): FarmWithStakedValue[] => {
      let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map(
        (farm) => {
          if (!farm.lpTotalInQuoteToken || !farm.quoteTokenPriceBusd) {
            return farm;
          }
             
          const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(
            farm.quoteTokenPriceBusd
          );

          const totalTokenStaked = farm.extras.totalTokenStaked;
          const rewardPerBlock = farm.extras.rewardPerBlock;
          
          const lpPriceBusd =
          farm.pid === 1
          ? totalLiquidity.div(totalTokenStaked)
          : cakePrice;

          const { cakeRewardsApr } = getFarmAprV3(
            totalTokenStaked,
            rewardPerBlock,
            lpPriceBusd,
            cakePrice
          );
          return {
            ...farm,
            apr: cakeRewardsApr,
          };
        }
      );

      return farmsToDisplayWithAPR;
    },
    [cakePrice]
  );

  const chosenFarmsMemoized = useMemo(() => {
    return farmsList(activeFarms);
  }, [activeFarms, farmsList]);

  chosenFarmsLength.current = chosenFarmsMemoized.length;

  return (
    <div>
      <div className="py-8 flex md:justify-center flex-col md:flex-row items-center">
        {chosenFarmsMemoized.map((farm) => (
          <FarmCard
            key={farm.pid}
            farm={farm}
            displayApr={getDisplayApr(farm.apr)}
            cakePrice={cakePrice}
            account={account as any}
            removed={false}
          />
        ))}
      </div>
    </div>
  );
}
