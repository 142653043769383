import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import farmsConfig from "config/constants/farms";
import fetchFarms from "./fetchFarms";
import fetchFarmsPrices from "./fetchFarmsPrices";
import {
  fetchFarmUserEarnings,
  fetchFarmUserTokenBalances,
  fetchFarmUserAllowances,
  fetchFarmUserStakedBalances,
} from "./fetchFarmUser";
import { SerializedFarmsState, SerializedFarm } from "../types";

const noAccountFarmConfig = farmsConfig.map((farm) => ({
  ...farm,
  userData: {
    allowance: "0",
    tokenBalance: "0",
    stakedBalance: "0",
    earnings: "0",
  },
}));

const initialState: SerializedFarmsState = {
  data: noAccountFarmConfig,
  userDataLoaded: false,
};

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<
  SerializedFarm[],
  number[]
>("farms/fetchFarmsPublicDataAsync", async (pids) => {
  const farmsToFetch = farmsConfig.filter((farmConfig) =>
    pids.includes(farmConfig.pid)
  );

  const farms = await fetchFarms(farmsToFetch);
  const farmsWithPrices = await fetchFarmsPrices(farms)

  // Filter out price helper LP config farms
  const farmsWithoutHelperLps = farmsWithPrices.filter(
    (farm: SerializedFarm) => {
      return farm.pid || farm.pid === 0;
    }
  );

  return farmsWithoutHelperLps;
});

interface FarmUserDataResponse {
  pid: number;
  allowance: string;
  tokenBalance: string;
  stakedBalance: string;
  earnings: string;
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  { account: string; pids: number[] }
>("farms/fetchFarmUserDataAsync", async ({ account, pids }) => {
  const farmsToFetch = farmsConfig.filter((farmConfig) =>
    pids.includes(farmConfig.pid)
  );
  const wagmiFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch.find(farm => farm.pid === 1)!);
  const wagmiOnlyFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch.find(farm => farm.pid === 2)!);
  
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(
    account,
    farmsToFetch
  );
  const userStakedBalances = await fetchFarmUserStakedBalances(
    account,
    farmsToFetch
  );

  const wagmiBnbEarnings = await fetchFarmUserEarnings(account, farmsToFetch.find(farm => farm.pid === 1)!);
  const wagmiOnlyfarmearnings = await fetchFarmUserEarnings(account, farmsToFetch.find(farm => farm.pid === 2)!);

  return farmsToFetch.map((farm, index) => {
    return {
      pid: farmsToFetch[index].pid,
      allowance: farm.pid === 1 ? wagmiFarmAllowances[0] : wagmiOnlyFarmAllowances[0],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: farm.pid === 1 ? wagmiBnbEarnings[0] : wagmiOnlyfarmearnings[0],
    };
  });
});

export const farmsSlice = createSlice({
  name: "Farms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      state.data = state.data.map((farm) => {
        const liveFarmData = action.payload.find(
          (farmData) => farmData.pid === farm.pid
        );
        return { ...farm, ...liveFarmData };
      });
    });

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { pid } = userDataEl;
        const index = state.data.findIndex((farm) => farm.pid === pid);
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
      state.userDataLoaded = true;
    });
  },
});

export default farmsSlice.reducer;
