import React from "react";
import { Token } from "config/entities/token";
import TokenPairImage from "components/TokenImage";
import BigNumber from "bignumber.js";

export interface ExpandableSectionProps {
  lpLabel?: string;
  multiplier?: string;
  //   isCommunityFarm?: boolean;
  token: Token;
  quoteToken: Token;
  earnings: BigNumber;
  apr: string | null;
}

export default function CardHeading({
  lpLabel,
  token,
  quoteToken,
  apr,
}: ExpandableSectionProps) {

  return (
    <>
      <div className="flex items-center mb-4 md:mb-0">
        <TokenPairImage primaryToken={token} secondaryToken={quoteToken} />
        <span className="text-base text-body-color font-medium">
          {lpLabel?.split(" ")[0]}
        </span>
      </div>
      <div className="-mx-14 mt-4 flex justify-center">
        <div className="text-right inline-block mx-4">
          <span className="text-xl">APR</span>
          <span className="text-lg inline-block mx-2">{apr || "-"}</span>
        </div>
      </div>
    </>
  );
}
