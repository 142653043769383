import axios, { AxiosError } from "axios";
import Qs from "qs";
import getNewsLetterUrl from "./urlHelpers";

interface ApiResponse {
  data: {
    success: boolean;
    message: string;
  };
}
type addToNewsLetterReturnType = boolean;

const newsLetterUrl = getNewsLetterUrl();
export const addToNewsLetter = async (
  firstName: string,
  lastName: string,
  email: string
): Promise<addToNewsLetterReturnType> => {
  let functionResponce = false;

  if (!newsLetterUrl) return false;
  await axios({
    url:
      newsLetterUrl +
      "?" +
      Qs.stringify({
        firstName,
        lastName,
        email,
      }),
    method: "GET",
    withCredentials: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
  })
    .then((res) => {
      const response = res as ApiResponse;
      // Show alert
      if (response.data.success === true) {
        functionResponce = true;
      }
    })
    .catch((err: AxiosError) => {
      functionResponce = false;
    });

  return functionResponce;
};
