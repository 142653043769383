import useLocalStorage from "hooks/useLocalStorage";
import useToast from "hooks/useToast";
import React, { useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { addToNewsLetter } from "utils/newsLetterHelpers";
import Button from "./Button/Button";
import WagmiFist from "./WagmiFist";
import ModalActions from "./widgets/Modal/ModalActions";

const Modal = (props: { onDismiss?: () => void }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [approve, setApprove] = useState(false);
  const [pending, setPending] = useState(false);

  const { toastError } = useToast();

  // Submitted
  const [sent, setSent] = useLocalStorage("added_to_list", false);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    setPending(true);
    // submit to spreadsheet
    const success = await addToNewsLetter(firstName, lastName, email);
    if (success) {
      setSent(true);
    } else {
      toastError("Failed", "Please try again");
    }
    setPending(false);
  };

  if (sent) {
    return (
      <div className="w-full max-w-sm bg-white p-8 rounded-lg text-center text-xl md:text-3xl">
        <BiCheckCircle className="mx-auto text-teal-500 h-6 w-6" />
        We'll keep you up to date <WagmiFist />
        <Button className="w-full mx-0" onClick={props.onDismiss}>
          Close
        </Button>
      </div>
    );
  }
  return (
    <div className="bg-white p-8 md:px-16 space-y-6 max-h-[calc(100vh-20px)] sm:max-w-lg md:max-w-2xl overflow-y-auto">
      <h5 className="text-2xl mb-4 font-normal">
        Subscribe to our news letter
      </h5>
      <form action="#" onSubmit={handleSubmit}>
        <div className="space-y-4 mb-4">
          <input
            type="text"
            inputMode="text"
            onChange={(e) => setFirstName(e.currentTarget.value)}
            placeholder="First name"
            value={firstName}
            className="py-3 px-2 w-full bg-gray-50 text-gray-900 outline-none ring
          ring-gray-200 focus:ring-blue-300"
          />
          <input
            type="text"
            inputMode="text"
            onChange={(e) => setLastName(e.currentTarget.value)}
            placeholder="Last name"
            value={firstName}
            className="py-3 px-2 w-full bg-gray-50 text-gray-900 outline-none ring
          ring-gray-200 focus:ring-blue-300"
          />
          <input
            type="email"
            inputMode="email"
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="Email address"
            value={email}
            className="py-3 px-2 w-full bg-gray-50 text-gray-900 outline-none ring
          ring-gray-200 focus:ring-blue-300"
          />
        </div>
        <p className="mb-3">
          From time to time, we would like to contact you about our products and
          services, as well as other content that may be of interest to you. If
          you consent to us contacting you for this purpose, please tick below
          to say how you would like us to contact you:
        </p>
        <div>
          <label
            htmlFor="approve"
            className="text-sm flex items-center cursor-pointer"
          >
            <input
              type="checkbox"
              name="approve"
              id="approve"
              className="mr-2 cursor-pointer"
              onChange={() => setApprove((p) => !p)}
              checked={approve}
            />
            <span>
              I agree to receive other communications from WAGMI4EVER{" "}
              <WagmiFist />
            </span>
          </label>
        </div>
        <em className="text-sm text-amber-700 leading-snug">
          You can unsubscribe from these communications at any time.
        </em>
        <p className="text-sm text-gray-500">
          By clicking submit below, you consent to allow WAGMI4EVER{" "}
          <WagmiFist /> to store and process the personal information submitted
          above to provide you the content requested.
        </p>
        <ModalActions>
          <Button
            className="w-full !bg-red-600 !text-white mx-0"
            variant="secondary"
            onClick={props.onDismiss}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            className="w-full mx-0 ring-transparent"
            disabled={
              !approve ||
              !(
                email.length > 0 &&
                lastName.length > 0 &&
                firstName.length > 0
              ) ||
              pending
            }
          >
            Submit
          </Button>
        </ModalActions>
      </form>
    </div>
  );
};

const NewsLetterModal = React.memo(Modal);
export default NewsLetterModal;
