import React from "react";
import Button from "components/Button/Button";
import Layout from "components/Layout";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Layout title="404 Not found.">
      <div className="flex justify-center items-center h-screen w-full px-4 bg-white ">
        <div className="flex flex-col justify-center items-center max-w-md text-center ">
          <div className="font-bold text-7xl">404</div>
          Hmm, we could not find the page you were searching for, but we can
          take you to a page that exists.
          <Link to="/">
            <Button
              className="!uppercase block my-8 "
              variant="outlined"
            >
              Back to Home
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}
