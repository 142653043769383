// import { ChainId } from "config/constants";
import { addresses, ChainId } from "config/constants";
import { Address } from "config/constants/types";

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID as unknown as keyof Address;
  return (address[chainId] ? address[chainId] : address[ChainId.TESTNET])!;
};

export const getWagmiAddress = (pid: number) => {
  return pid === 1 ? getAddress(addresses.wagmi4ever) : getWagmiOnlyAddress();
};

export const getWagmiOnlyAddress = () => {
  return getAddress(addresses.onlyWagmi);
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getBnbPriceHelperAddress = () => {
  return getAddress(addresses.bnbPriceHelper);
};